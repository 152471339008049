import React, {useEffect, useState} from 'react';
import kasperskyBannerTR from "../images/tmp/kaspersky-promo-turkey.png"
import kasperskyBannerKO from "../images/tmp/kaspersky-promo-korea.png"

const kasperskyProLinkTR = 'https://store.bittorrent.com/849/purl-utprokasperskyavtr'
const kasperskyProVPNLinkTR = 'https://store.bittorrent.com/849/purl-utprovpnkasperskyavtr'
const kasperskyProLinkKO = 'https://store.bittorrent.com/849/purl-utprokasperskyiskr'
const kasperskyProVPNLinkKO = 'https://store.bittorrent.com/849/purl-utprovpnkasperskyiskr'

const KasperskyHook = (locale) => {

	const [isTR, setIsTR] = useState(false);
	const [isKO, setIsKO] = useState(false);
	const [kasperskyBanner, setKasperskyBanner] = useState([]);

	useEffect(() => {
			// if(locale === "tr") {
			// 	setIsTR(true)
			// 	setKasperskyBanner(<a href={kasperskyProLinkTR} target="_blank" className="d-flex w-100 justify-content-center mb-3">
			// 		<img src={kasperskyBannerTR} style={{ maxWidth: '800px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}/>
			// 	</a>
			// 	)
			// }
			// else if (locale === "ko") {
			// 	setIsKO(true)
			// 	setKasperskyBanner(<a href={kasperskyProLinkKO} target="_blank" className="d-flex w-100 justify-content-center">
			// 		<img src={kasperskyBannerKO} style={{ maxWidth: '800px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}/>
			// 	</a>
			// 	)
			// }
		},[locale]
	)


  return (
  	{
  		isTR,
  		isKO,
  		kasperskyBanner
  	}
  )
}

//Higher-Order Component(HOC)
//create new component so KasperskyHook can be used in class component
function KasperskyHOC(Component) {
  return function WrappedComponent(props) {
    const myHookValue = KasperskyHook(props.pageContext.locale);
    return <Component {...props} myHookValue={myHookValue} />;
  }
}

export {KasperskyHook, kasperskyProLinkTR, kasperskyProVPNLinkTR, kasperskyProLinkKO, kasperskyProVPNLinkKO, KasperskyHOC}