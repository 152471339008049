import React, { useContext, useRef, useState, useEffect, createRef } from "react"
import useTranslations from "./../utils/useTranslations"
import Button from "../components/button"
import { CompatibilityContext } from "./compatibilityContext";
import { LocaleContext } from "./layout"
import { kasperskyProLinkTR, kasperskyProVPNLinkTR, kasperskyProLinkKO, kasperskyProVPNLinkKO} from "../components/kasperskyHook"
import { PromoPopup, PromoPopupHandler, promoPrices, getPromoLinks } from '../components/promo'
import getCookie from "../utils/getCookie"
import {WebAf, WebPro, WebProVpn} from "./cartLinks"
import { ANDROID_LINK_FREE, ANDROID_LINK_PRO, getAndroidLink } from "../utils/getAndroidLink";
import { getCountry } from "../utils/country";
import BENCH_CLIENT from "../utils/benchClient"

const ComparisonPanels = ({pageContext: { text, pageName, isMac, isKasperskyKO, isKasperskyTR }, noShadow, children}) => {
  const t = useTranslations(text)
  const page = pageName
  const compatibilityHandler = useContext(CompatibilityContext)
  const { locale } = React.useContext(LocaleContext)
  const googlePlayButton = `/images/google-play-badges/google-play-badge-${locale}.png`
  var product;
  var productOs;
  var pageType;
  var buyWithCrypto;
  var buyWebWithCrypto;
  var titleProduct;

  const subtitles = {}
  subtitles.basic = useRef(null);
  subtitles.adFree = useRef(null);
  subtitles.pro = useRef(null);
  subtitles.proVPN = useRef(null);

  const panel = {}
  panel.basic = useRef(null);
  panel.adFree = useRef(null);
  panel.pro = useRef(null);
  panel.proVPN = useRef(null);

  const freePriceInnerRef = useRef(null);

  const [subtitleStyle, setSubtitleStyle] = useState({});
  const [freePriceStyle, setFreePriceStyle] = useState({});

  const proVpnNotSupportedGeos = ['IN'];

  const [isProVPNSupported, setIsProVPNSupported] = useState(true);

  const [androidLinkFree, setAndroidLinkFree] = useState(ANDROID_LINK_FREE)
  const [androidLinkPro, setAndroidLinkPro] = useState(ANDROID_LINK_PRO)

  function adjustSubtitleHeight(){
        let maxHeight = 0;
        for (let key in subtitles){
          if(subtitles[key].current.clientHeight){
            let currentHeight = subtitles[key].current.clientHeight

            if(maxHeight < currentHeight){
              maxHeight = currentHeight
            }
          }
        }

        setSubtitleStyle({
          height:`${maxHeight}px`,
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        })
  }

  function adjustFreePriceFontSize(){
      let parentWidth = panel.basic.current.clientWidth
      if(parentWidth !== 0){
        let width = freePriceInnerRef.current.clientWidth
        if(width > parentWidth-30){
          let fs = window.getComputedStyle(freePriceInnerRef.current, null).getPropertyValue('font-size');
          fs = parseFloat(fs)
          let newFs = fs-5 + "px"
          setFreePriceStyle({
            fontSize:newFs,
            lineHeight:'normal'
          })
        }
      }
  }

  function getCountryCode(){
    let countryCode = getCountry()
    return countryCode;
  }

  function handleVPNSuppport(countryCode){
    if(proVpnNotSupportedGeos.includes(countryCode) && (homepage === true || product === "Web" || product === "Classic")){
      setIsProVPNSupported(false);
    }
  }

  useEffect(() => {
    adjustSubtitleHeight();

    let countryCode= getCountryCode(); 
    handleVPNSuppport(countryCode);
  }, [])

  useEffect(() => {
    adjustFreePriceFontSize()
    setAndroidLinkFree(getAndroidLink('free'))
    setAndroidLinkPro(getAndroidLink('pro'))
  }, [freePriceStyle])

  if (page === 'index') { var homepage = true; var panelGutter = true; productOs = ["Win"]; pageType = "home"}
  if (page === 'web') { var btwebfree = true; var panelGutter = false; product = "Web"; productOs = ["Win"]; titleProduct=true; buyWebWithCrypto = false; }
  if (page === 'web_mac') { var btwebfree = true; var panelGutter = false; product = "Web"; productOs = ["Osx"]; titleProduct=true; buyWebWithCrypto = false; }
  if (page === 'web_pro') { var webPremium = true; var panelGutter = false; product = "Web"; productOs = ["Win", "Osx"]; titleProduct=true; buyWebWithCrypto = false; }
  if (page === 'web_compare') { var webcompare = true; var panelGutter = false; product = "Web"; productOs = ["Win", "Osx"]; titleProduct=true; buyWebWithCrypto = false; var showEULA=isMac}
  if (page === 'webpro-offer') { var webProOffer = true; var panelGutter = true; product = "Web"; productOs = ["Win", "Osx"]; titleProduct=true;}
  if (page === 'desktop') { var classicfree = true; product = "Classic"; productOs = ["Win"]; pageType = "panels"; buyWithCrypto=false; titleProduct=true}
  if (page === 'pro') { var classicPremium = true; product = "Classic"; productOs = ["Win"]; pageType = "premium"; buyWithCrypto=false; titleProduct=true}
  if (page === 'desktop_compare') { var classiccompare = true; product = "Classic"; productOs = ["Win"]; pageType = "dedicatedpanels"; buyWithCrypto=false; titleProduct=true}
  if (page === 'mobile') { var androidfree = true; product = "Android"; productOs = ["Android"]}


  if(isKasperskyTR || isKasperskyKO) {
    buyWithCrypto = false
  }

  var linkBasic = (isMac) ? '/web/downloads/complete/track/stable/os/mac/' : "/web/downloads/complete/track/stable/os/win/"
  if(classiccompare || classicPremium || classicfree){
    linkBasic = "/downloads/complete/track/stable/os/win/"
  }


  const isPromoCalloutPage = homepage || btwebfree || webPremium || webcompare || classicfree || classicPremium || classiccompare
  const {
    handlePromoPopupClose, 
    handlePromoPopupOpen,
    showPromoDiscount,
    showPromoPopup 
  } = PromoPopupHandler()

  const showAnyPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount: Object.values(showPromoDiscount).some(promo => promo === true);
  const showProVpnPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.proVpn;
  const showProPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.pro;
  const showAdFreePromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.adFree;

  const popupRef = createRef()

  const handleClick = (e, link, type, homepageProduct)=>{
    // show promoPopup on free download click
    // when popup is not visible
    // and not already showing promo discounts
    // use false if statement to disable promotion
    // if(action === "downloading" && !showPromoPopup && !showPromoDiscount && !isKasperskyTR && !isKasperskyKO) {
    if(false) {
      handlePromoPopupOpen(popupRef, e)
    }
    else {
      const action = type === "basic" ? "download" : "buy";
      const productName = product ? product : (homepageProduct ? homepageProduct : 'Unknown');

      compatibilityHandler(e, `${action}ing`, productName, productOs, link)
      BENCH_CLIENT.ping(`click.${productName.toLowerCase()}_${action}.${type}`);
    }
  }

  let webProVPNLink = (isMac) ? WebProVpn.webMac : WebProVpn.webWin
  let webProLink =    (isMac) ? WebPro.webMac    : WebPro.webWin
  let webAdFreeLink = (isMac) ? WebAf.webMac     : WebAf.webWin

  let proVPNLink = (pageType) ? `https://store.utorrent.com/849/purl-utwebprovpn6995?x-source=utclassicprovpn-${pageType}` : "https://store.utorrent.com/849/purl-utwebprovpn6995"
  let proLink = (pageType) ? `https://store.utorrent.com/849/purl-utproweb?x-source=utclassicpro-${pageType}` : "https://store.utorrent.com/849/purl-utproweb"
  let adFreeLink = (pageType) ? `https://store.utorrent.com/849/purl-utafwebw?x-source=utclassicadfree-${pageType}` : "https://store.utorrent.com/849/purl-utafwebw"

  //overwrite Pro and ProVPN classic links for Kaspersky Test
  if(isKasperskyTR) {
    proVPNLink = kasperskyProVPNLinkTR
    proLink = kasperskyProLinkTR
  }
  else if(isKasperskyKO) {
    proVPNLink = kasperskyProVPNLinkKO
    proLink = kasperskyProLinkKO
  }

  if(product === "Web"){
    proVPNLink = webProVPNLink
    proLink = webProLink
    adFreeLink = webAdFreeLink

    if(webProOffer) {
      proVPNLink = "https://gateway.lavasoft.com/utorrentpro-vpn/cart/?mkey10=ScannerLP"
      proLink = "https://gateway.lavasoft.com/utorrentpro/cart/?mkey10=ScannerLP"
    }
  }

  let panelShadowNone = (noShadow) ? "panelShadowNone" : "" 
  let panelTriplet = (panelGutter) ? "sexy-panel" : ""
  let panelNoBorder = (panelGutter) ? "sexy-panel-too" : ""
  let panelPromo = (showAnyPromo) ? "panel-promo" : ""

  let proVPNCryptoLink = "https://cryptopayments.utorrent.com/checkout/?add-to-cart=21";
  let proCryptoLink = "https://cryptopayments.utorrent.com/checkout/?add-to-cart=14";
  let adFreeCryptoLink = "https://cryptopayments.utorrent.com/checkout/?add-to-cart=19";

  let webProVPNCryptoLink = "https://cryptopayments.utorrent.com/checkout/?add-to-cart=1381";
  let webProCryptoLink = "https://cryptopayments.utorrent.com/checkout/?add-to-cart=1380";

  //////
  let webProLink2 = webProLink;
  let webProVPNLink2 = webProVPNLink;
  let webProCryptoLink2 = webProCryptoLink;
  let webProVPNCryptoLink2 = webProVPNCryptoLink;
  let proLink2 = proLink;
  let proVPNLink2 = proVPNLink;
  let proCryptoLink2 = proCryptoLink;
  let proVPNCryptoLink2 = proVPNCryptoLink;
  let webAdFreeLink2 = webAdFreeLink;
  let adFreeLink2 = adFreeLink;
  let adFreeCryptoLink2 = adFreeCryptoLink;

  const promoPercent = promoPrices.percent;


  if (showProPromo) {
    const newLinks = getPromoLinks({webProLink, proLink});
    webProLink = newLinks.webProLink;
    proLink = newLinks.proLink;
  }
  if (showProVpnPromo) {
    const newLinks = getPromoLinks({webProVPNLink, proVPNLink});
    webProVPNLink = newLinks.webProVPNLink;
    proVPNLink = newLinks.proVPNLink;
  }
  if (showAdFreePromo) {
    const newLinks = getPromoLinks({webAdFreeLink, adFreeLink});
    webAdFreeLink = newLinks.webAdFreeLink;
    adFreeLink = newLinks.adFreeLink;
  }

  webProLink2 = webProLink;
  webProVPNLink2 = webProVPNLink;
  webProCryptoLink2 = webProCryptoLink;
  webProVPNCryptoLink2 = webProVPNCryptoLink;
  proLink2 = proLink;
  proVPNLink2 = proVPNLink;
  proCryptoLink2 = proCryptoLink;
  proVPNCryptoLink2 = proVPNCryptoLink;
  webAdFreeLink2 = webAdFreeLink;
  adFreeLink2 = adFreeLink;
  adFreeCryptoLink2 = adFreeCryptoLink;

  ///////

  return (    
     <>
        <div id="comparison-panels" className={`container mt-5 mb-sm-5 px-0 ${classicPremium || classicfree || homepage || btwebfree  || webPremium  ? 'staggered-design' : ''}`}>
          <div className={homepage || androidfree || webProOffer ? 'row d-flex align-items-start justify-content-center mx-0' : 'row d-flex align-items-start justify-content-center column-gaps mx-0 position-relative z-1'}>
            <div ref={panel.proVPN} className={androidfree === true || !isProVPNSupported ? 'd-none' : `col-12 col-sm-10 col-md-8 col-lg-3 panels-col-lg-3 order-3 order-lg-1 d-flex flex-column align-items-center justify-content-start panelShadow panelWidth panelOther ${panelShadowNone} ${panelNoBorder} ${panelPromo}`}>
              <div className="panelTitle mt-4">PRO+VPN</div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle mt-3 mb-4" ref={subtitles.proVPN} style={subtitleStyle}><div>{t("provpnPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice">
                  {/* {((!showPromoDiscount) || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO) */}
                  {(!showProVpnPromo || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO)
                    ? "$69.95"
                    : (
                      <>
                        <div className="promo-limited-time">{t("Limited Time Only")}</div>
                        <div className="promo-percent-off">{promoPercent} {t("Off")}</div>
                        <div className="promo-list-price">
                          {t("List Price")}:
                          <span className="promo-original-price">$69.95</span>
                        </div>
                      </>
                    )

                  }
                </div>
                <div className="panelsPeriod mb-3">{t("per year")}</div>
              </div>
              <Button panel={panel.proVPN} onClick={(e) => handleClick(e, proVPNLink2, "provpn")} id="panels-provpn-buynow" href={proVPNLink2} type="button" className={homepage === true ? 'd-none' : 'btn btn-outline-primary button-outlined text-size-16'}>{t("BUY NOW")}</Button>
              <div className={homepage === true ? 'd-flex align-items-center justify-content-start' : 'd-none'}>
                <Button id="panels-hp-web-provpn-link" onClick={(e) => handleClick(e, webProVPNLink2, "provpn", "Web")} href={webProVPNLink2} type="button" className="btn btn-primary-panels button-filled button-panels">WEB</Button>
                <Button id="panels-hp-classic-provpn-link" onClick={(e) => handleClick(e, proVPNLink2, "provpn", "Classic")} href={proVPNLink2} type="button" className="btn btn-outline-primary button-outlined ml-2 button-panels">CLASSIC</Button>
              </div>
              {
                buyWithCrypto &&
                <div className="mt-2 mb-1 text text-center">
                  <a onClick={(e) => handleClick(e, proVPNCryptoLink2, "provpn")} href={proVPNCryptoLink2} id="panels-provpn-buynow-crypto" rel="noopener noreferrer" target="_blank"><u>{t(`Buy with crypto`)}</u><br /></a>
                </div>
              }
              {
                buyWebWithCrypto &&
                <div className="mt-2 mb-1 text text-center">
                  <a onClick={(e) => handleClick(e, webProVPNCryptoLink2, "provpn")} href={webProVPNCryptoLink2} id="panels-web-provpn-buynow-crypto" rel="noopener noreferrer" target="_blank"><u>{t(`Buy with crypto`)}</u><br /></a>
                </div>
              }
              <div className="mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree === true || webPremium === true ? 'panelBullets w-100 uniform' : 'panelBullets w-100 uniform-short'}>
                  <li>{t("provpnBullet1")}</li>
                  <li>{t("provpnBullet2")}</li>
                  <li>{t("provpnBullet3")}</li>
                  <li className={classiccompare === true || btwebfree === true || webProOffer ? 'd-none' : ''}>{t("provpnBullet4")}</li>
                  <li className={classiccompare === true || webcompare === true || classicPremium === true || webPremium === true || btwebfree === true || homepage === true || classicfree === true || webProOffer ? 'd-none' : ''}>{t("provpnBullet5")}</li>
                </div>
              </div>
            </div>
            <div ref={panel.pro} className={`col-12 col-sm-10 col-md-8 panels-col-lg-3 col-lg-3 order-1 order-lg-2 d-flex flex-column align-items-center justify-content-start panelShadowpop panelWidth px-0 mx-0 ${panelShadowNone} ${panelTriplet} ${panelPromo}`}>
              {
                //remove most popular for android
              }
              {product === "Web" && (
                <div className="panels-new-improved-badge">
                  <div className="badge-text">
                    {t("New & improved")}
                  </div>
                </div>
              )}
              <div className="popular d-flex align-items-center justify-content-center">{t("POPULAR")}</div>
              <div className="panelTitle d-flex align-items-center mt-4">
                {
                  (product === "Classic") &&
                  <div className="new-badge">{t("NEW")}</div>
                }
                PRO
              </div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle px-3 mt-3 mb-4" ref={subtitles.pro} style={subtitleStyle}><div>{t("proPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice px-3">
                  {/* {((!showPromoDiscount) || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO) */}
                  {((!showProPromo) || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO)
                    ? <span className={androidfree === true ? 'd-none' : 'd-block'}>$19.95</span>
                    : (
                      <>
                        <div className="promo-limited-time">{t("Limited Time Only")}</div>
                        <div className="promo-percent-off">{promoPercent} {t("Off")}</div>
                        <div className="promo-list-price">
                          {t("List Price")}:
                          <span className="promo-original-price">$19.95</span>
                        </div>
                      </>
                    )
                  }
                  {
                    ((androidfree === true) && !isPromoCalloutPage) &&
                    <span className='d-block'>$2.99</span>
                  }
                </div>

                <div className={androidfree === true ? 'invisible mb-3' : 'panelsPeriod mb-3'}>{t("per year")}</div>
              </div>
              <Button panel={panel.pro} onClick={(e) => handleClick(e, proLink2, "pro")} id="panels-pro-buynow" href={proLink2} type="button" className={homepage === true || androidfree === true ? 'd-none' : 'btn btn-outline-primary button-outlined'}>{t("BUY NOW")}</Button>
              {
                (product === "Classic") &&
                <a className="panels-pro-free-trial-link mt-3" href="https://store.bittorrent.com/849/purl-utprotrialweb14" target="_blank">{t("Or try free for 14 days")}</a>
              }
              <div className={homepage === true ? 'd-flex align-items-center justify-content-start' : 'd-none'}>
                <Button id="panels-hp-web-pro-link" onClick={(e) => handleClick(e, webProLink2, "pro", "Web")} href={webProLink2} type="button" className="btn btn-primary-panels button-filled button-panels">WEB</Button>
                <Button id="panels-hp-classic-pro-link" onClick={(e) => handleClick(e, proLink2, "pro", "Classic")} href={proLink2} type="button" className="btn btn-outline-primary button-outlined ml-2 button-panels">CLASSIC</Button>
              </div>
              <a href={androidLinkPro} id="panels-android-pro" className={androidfree === true ? 'index-mobile-google-play' : 'd-none'} target="_blank" rel="noopener noreferrer">
                <img className="google-play-button img-fluid" src={googlePlayButton} alt="µTorrent Pro for Android Google Play Store badge"></img>
              </a>
              {
                buyWithCrypto &&
                <div className="mt-2 mb-1 text text-center">
                  <a onClick={(e) => handleClick(e, proCryptoLink2, "pro")} href={proCryptoLink2} id="panels-pro-buynow-crypto" rel="noopener noreferrer" target="_blank"><u>{t(`Buy with crypto`)}</u><br /></a>
                </div>
              }
              {
                buyWebWithCrypto &&
                <div className="mt-2 mb-1 text text-center">
                  <a onClick={(e) => handleClick(e, webProCryptoLink2, "pro")} href={webProCryptoLink2} id="panels-web-pro-buynow-crypto" rel="noopener noreferrer" target="_blank"><u>{t(`Buy with crypto`)}</u><br /></a>
                </div>
              }
              <div className="mx-3 mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree === true || webPremium === true ? 'panelBullets w-100 h-100 uniform' : 'panelBullets w-100 h-100 uniform-short'}>
                  {
                    isKasperskyTR &&
                    <li style={{listStyleImage:`url('https://cdn-assets.bittorrent.com/optimize/post-download-page/check-mark-green.svg')`}}><b className="text-dark">1 PC için Kaspersky AV</b></li> 
                  }
                  {
                    isKasperskyKO &&
                    <li style={{listStyleImage:`url('https://cdn-assets.bittorrent.com/optimize/post-download-page/check-mark-green.svg')`}}><b className="text-dark">PC 1대용 Kaspersky Security</b></li> 
                  }
                  <li>{t("proBullet1")}</li>
                  <li>{t("proBullet2")}</li>
                  <li>{t("proBullet3")}</li>
                  <li className={androidfree === true || btwebfree || webPremium || webcompare ? 'd-none' : ''}>{t("proBullet4")}</li>
                  <li className={homepage=== true || androidfree === true || classiccompare === true || webcompare === true || btwebfree === true || webPremium === true || webProOffer ? 'd-none' : ''}>{t("proBullet5")}</li>
                 {/* <li className={(classicPremium === true) && (btwebfree !== true || webPremium === true) ? '' : 'd-none'}>{t("proBullet6")}</li> */}
                </div>
              </div>
            </div>
            <div ref={panel.adFree} className={androidfree === true || webProOffer ? 'd-none' : `col-12 col-sm-10 col-md-8 panels-col-lg-3 col-lg-3 order-2 order-lg-3 d-flex flex-column align-items-center justify-content-start panelShadowaf panelWidth panelOther ${panelShadowNone} ${panelNoBorder} ${panelPromo}`}>
              <div className="panelTitle mt-4">AD-FREE</div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle mt-3 mb-4" ref={subtitles.adFree} style={subtitleStyle}><div>{t("afPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice">
                  {/* {(!showPromoDiscount || !isPromoCalloutPage || isKasperskyTR || isKasperskyKO) */}
                  {!showAdFreePromo
                    ? "$4.95"
                    : (
                      <>
                        <div className="promo-limited-time">{t("Limited Time Only")}</div>
                        <div className="promo-percent-off">{promoPercent} {t("Off")}</div>
                        <div className="promo-list-price">
                          {t("List Price")}:
                          <span className="promo-original-price">$4.95</span>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className="panelsPeriod mb-3">{t("per year")}</div>
              </div>
              <Button panel={panel.adFree} onClick={(e) => handleClick(e, adFreeLink2, "adfree")} id="panels-af-buynow" href={adFreeLink2} type="button" className={homepage === true ? 'd-none' : 'btn btn-outline-primary button-outlined'}>{t("BUY NOW")}</Button>
              <div className={homepage === true ? 'd-flex align-items-center justify-content-start' : 'd-none'}>
                <Button id="panels-hp-web-af-link" onClick={(e) => handleClick(e, webAdFreeLink2, "adfree", "Web")} href={webAdFreeLink2} type="button" className="btn btn-primary-panels button-filled button-panels">WEB</Button>
                <Button id="panels-hp-classic-af-link" onClick={(e) => handleClick(e, adFreeLink2, "adfree", "Classic")} href={adFreeLink2} type="button" className="btn btn-outline-primary button-outlined ml-2 button-panels">CLASSIC</Button>
              </div>
              {
                buyWithCrypto &&
                <div className="mt-2 text text-center">
                  <a onClick={(e) => handleClick(e, adFreeCryptoLink2, "adfree")} href={adFreeCryptoLink2} id="panels-af-buynow-crypto" rel="noopener noreferrer" target="_blank"><u>{t(`Buy with crypto`)}</u><br /></a>
                </div>
              }
              {
                buyWebWithCrypto &&
                <div className="text crypto-logos-space">
                  &nbsp;
                </div> 
              }
              <div className="mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree || webPremium  ? 'panelBullets w-100 uniform' : 'panelBullets w-100 uniform-short'}>
                  <li>{t("afBullet1")}</li>
                  <li className={classicfree === true || classicPremium === true || classiccompare === true ? 'd-none' : ''}>{t("afBullet2")}</li>
                  <li className={classicfree === true || classicPremium === true || classiccompare === true ? 'd-none' : ''}>{t("afBullet3")}</li>
                </div>
              </div>
            </div>
            <div ref={panel.basic} id="basicpanel" className={homepage === true || webProOffer ? 'd-none' : `col-12 col-sm-10 col-md-8 panels-col-lg-3 col-lg-3 order-4 d-flex flex-column align-items-center justify-content-start panelShadow panelWidth panelOther ${panelShadowNone} ${panelPromo}`}>
              <div className="panelTitle mt-4">BASIC</div>
              { titleProduct &&
                <p className="panelTitleProduct"> {t(`for ${product}`)}</p>
              }
              <div className="panelSubtitle mt-3 mb-4" ref={subtitles.basic} style={subtitleStyle}><div>{t("basicPanelTitle")}</div></div>
              <div className="panelSubline mb-4">&nbsp;</div>
              <div>
                <div className="panelsPrice"><span style={freePriceStyle} className="text-nowrap d-inline-block" ref={freePriceInnerRef}>{t("FREE")}</span></div>
                <div className="panelsPeriod mb-3">&nbsp;</div>
              </div>
              <Button panel={panel.basic} onClick={(e) => handleClick(e, linkBasic, "basic")} id="panels-basic-dlnow" href={linkBasic} type="button" className={androidfree === true ? 'd-none' : 'btn btn-outline-primary button-outlined'}>{t("DOWNLOAD NOW")}</Button>
              <a href={androidLinkFree} id="panels-android-free" className={androidfree === true ? 'index-mobile-google-play' : 'd-none'} target="_blank" rel="noopener noreferrer">
                <img className="google-play-button img-fluid" src={googlePlayButton} alt="µTorrent for Android Google Play Store badge"></img>
              </a>
              {
                (buyWithCrypto || buyWebWithCrypto) &&
                <div className="text crypto-logos-space">
                  &nbsp;
                </div>
              }
              <div className="mt-4 mw-100">
                <div className={classicPremium === true || classicfree === true || homepage === true || btwebfree === true || webPremium === true ? 'panelBullets w-100 uniform' : 'panelBullets w-100 uniform-short'}>
                  <li>{t("basicBullet1")}</li>
                  <li className={androidfree === true ? '' : 'd-none'}>{t("basicBullet2")}</li>
                  <li className={androidfree === true ? '' : 'd-none'}>{t("basicBullet3")}</li>
                  {
                    (showEULA) &&
                    <div className="panels-eula-container">
                      <div className="text panels-eula-text mt-2">
                        {t('By clicking Download Now, you agree to our Terms of Use, EULA & Privacy Policy', {
                          "Terms of Use" : <a href="https://www.bittorrent.com/legal/terms-of-use/" target="_blank" rel="noopenner noreferrer">{t(`Terms of Use`)}</a>,
                          "EULA" : <a href="https://www.bittorrent.com/legal/eula/" target="_blank" rel="noopenner noreferrer">{t(`EULA`)}</a>,
                          "Privacy Policy" : <a href="https://www.bittorrent.com/legal/privacy-policy/" target="_blank" rel="noopenner noreferrer">{t(`Privacy Policy`)}</a>,
                        })}
                      </div> 
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          {children}
        </div>
        {/* <PromoPopup show={showPromoPopup} ref={popupRef} closeEvent = {handlePromoPopupClose}/>         */}

     </>
  )
}

export default ComparisonPanels
